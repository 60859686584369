
import { SearchEntity } from "../../../Search.Entity";

export class QuoteStageSearchEntity extends SearchEntity {
  ID: string;
  QouteStageCode: string;
  QouteStageName: string; 
  Description: string;

  public constructor(QuoteStage: any = null) {
    super();

  }
}
