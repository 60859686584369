
import { IEntity } from "../../../IEntity.Entity";

export class QuoteStageEntity extends IEntity {
  ID: string;
  QouteStageCode: string;
  QouteStageName: string;
  InActive: boolean;
  Description: string;
  CompanyID: string;
  IsVisible: boolean
  QouteCount: number;
  public constructor(QuoteStage: any = null) {
    super();
  }
}
