import { IEntity } from "../../IEntity.Entity";
import { TaskTagEntity } from "./TaskTag.Entity";
export class ProjectEntity extends IEntity {
  ID: string;
  ProjectCode: string;
  ProjectName: string;
  PrjectType: string;
  Description: string;
  LeadUserID: string;
  CreatedDate: string;
  CreatedUserID: string;
  LeadUserName: string;
  DeadlineDate: string;
  NumberUser: number;
  WorkflowID: string;
  WorkflowName: string;
  ListUser: Array<ProjectUserEntity> = [];
  public constructor() {
    super();
    this.NumberUser = 0;
  }
}

import { SearchEntity } from "../../Search.Entity";
import { ProjectUserEntity } from "./ProjectUser.Entity";
export class ProjectSearchEntity extends SearchEntity {
  ID: string;
  ProjectCode: string;
  ProjectName: string;
  IsRecent: boolean;
  public constructor() {
    super();
    this.IsRecent = false;
  }
}

