
import { SearchEntity } from "../../Search.Entity";

export class FileProcessingGroupSearchEntity extends SearchEntity {
  ID: string;
  FileProcessingGroupName: string;
  InActive: boolean;
  CompanyID: string;
  public constructor(Partner: any = null) {
    super(Partner);
  }
}
