
import { SearchEntity } from "../../../Search.Entity";

export class ProductCategorySearchEntity extends SearchEntity {
  ID: string;
  ProductCategoryCode: string;
  ProductCategoryName: string;  
  Description: string;

  public constructor() {
    super();

  }
}
