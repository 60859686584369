import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { QuoteStageEntity } from './QuoteStage/QuoteStage.Entity';
@Injectable({
  providedIn: 'root'
})

export class QuoteStageService extends HttpService<QuoteStageEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/QuoteStages";
  }


}
