import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { ProductTypeEntity } from './ProductType/ProductType.Entity';
@Injectable({
  providedIn: 'root'
})

export class ProductTypeService extends HttpService<ProductTypeEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/ProductTypes";
  }


}
