
import { IEntity } from "../../../IEntity.Entity";

export class ProductCategoryEntity extends IEntity {
  ID: string;
  ProductCategoryCode: string;
  ProductCategoryName: string;
  InActive: boolean;
  Description: string;
  ParentID: string;
  CompanyID: string;
  IsVisible: boolean;


  public constructor() {
    super();
  }
}
