
import { SearchEntity } from "../../../Search.Entity";

export class ProductTypeSearchEntity extends SearchEntity {
  ID: string;
  ProductTypeCode: string;
  ProductTypeName: string;
  Description: string;

  public constructor() {
    super();

  }
}
