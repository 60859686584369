
import { SearchEntity } from "../../../Search.Entity";

export class OrderStageSearchEntity extends SearchEntity {
  ID: string;
  SaleOrderStageCode: string;
  SaleOrderStageName: string;  
  Description: string;

  public constructor(OrderStage: any = null) {
    super();

  }
}
