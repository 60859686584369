import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileProcessingGroupEntity } from './FileProcessingGroup.Entity';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
@Injectable({
  providedIn: 'root'
})

export class FileProcessingGroupService extends HttpService<FileProcessingGroupEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);

    this.url = "api/FileProcessingGroups";
  }

}
