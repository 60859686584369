
import { SearchEntity } from "../../../Search.Entity";

export class CampStageSearchEntity extends SearchEntity {
  ID: string;
  CampStageCode: string;
  CampStageName: string;
  Description: string;

  public constructor() {
    super();
  }
}
