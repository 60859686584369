
import { IEntity } from "../../../IEntity.Entity";

export class OrderStageEntity extends IEntity {
  ID: string;
  SaleOrderStageCode: string;
  SaleOrderStageName: string;
  InActive: boolean;
  Description: string;
  CompanyID: string;
  IsVisible: boolean;
  SaleOrderCount: number;
  public constructor(OrderStage: any = null) {
    super();
  }
}
