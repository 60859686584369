
import { IEntity } from "../../../IEntity.Entity";

export class CampStageEntity extends IEntity {
  ID: string;
  CampStageCode: string;
  CampStageName: string; 
  InActive: boolean;
  CompanyID: string;
  IsVisible: boolean;
  CampCount: number;
  public constructor() {
    super();

  }
}
