import { IEntity } from "../../IEntity.Entity";

export class ProductEntity extends IEntity {
  ID: string;
  ProductCode: string;
  ProductName: string;
  UnitPrice: number;
  Description: string;
  ProductCategoryID: string;
  ProductCategoryName: string;
  ProductTypeID: string;
  ProductTypeName: string;
  DiscountRate: number;
  TaxRate: number;
  AssignToID: string;
  AssignName: string;
  DefaultStockID: string;
  Barcode: string;
  UnitPriceOC: number;
  PurchasePriceOC: number;
  PurchasePrice: number;
  ShowUnitForDetailItem: boolean;
  CompanyID: string;
  IsVisible: boolean;
  IsService: boolean;
  LastestPriceOC: number;
  OpeningQuantity: number;
  ClosingQuantity: number;
  Unit: string;
  ContactID: string;

  public constructor() {
    super();
    this.UnitPrice = 0;
    this.UnitPriceOC = 0;
    this.DiscountRate = 0;
    this.TaxRate = 0;
    this.PurchasePriceOC = 0;
    this.PurchasePrice = 0;
  }
}
