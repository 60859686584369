
import { IEntity } from "../../../IEntity.Entity";

export class PurchaseStageEntity extends IEntity {
  ID: string;
  PurchaseStageCode: string;
  PurchaseStageName: string;
  InActive: boolean;
  Description: string;
  CompanyID: string;
  IsVisible: boolean;
  PurchaseCount: number;
  public constructor(PurchaseStage: any = null) {
    super();
  }
}
