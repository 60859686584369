
import { SearchEntity } from "../../Search.Entity";

export class CashReceiptSearchEntity extends SearchEntity {

  ID: string;
  CashReceiptDate: string;
  CashReceiptCode: string;
  CashReceiptName: string;
  ContactID: string;
  ContactCode: string;
  ContactName: string;
  ContactAddress: string;
  PartnerID: string;
  Description: string;
  TotalAmount: number;
  FromDate: string;
  ToDate: string;

  public constructor() {
    super();
    this.PeriodReportYear = new Date().getFullYear();
  }
}
