
import { IEntity } from "../../../IEntity.Entity";

export class ContactStageEntity extends IEntity {
  ID: string;
  StageCode: string;
  StageName: string;
  InActive: boolean;
  Description: string;
  CompanyID: string;
  IsVisible: boolean;
  ContactCount: number;

  public constructor() {
    super();   
  }
}
