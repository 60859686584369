import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
import { ProjectEntity } from './Project.Entity';
import { ProjectUserEntity } from './ProjectUser.Entity';
import { AccountEntity } from '../../Account/Models/Account.Entity';
@Injectable({
  providedIn: 'root'
})

export class ProjectService extends HttpService<ProjectEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.url = "api/Projects";
  }

  AddUser(body: Array<ProjectUserEntity>, IsShowLoading?: boolean): Observable<IEntity> {
    return this.intercept(this.http.post<IEntity>(this.url + `/AddUser`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
  DeleteUser(body: ProjectUserEntity, IsShowLoading?: boolean): Observable<IEntity> {
    return this.intercept(this.http.put<IEntity>(this.url + `/DeleteUser`, JSON.stringify(body), {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  GetUserByProject(ProjectID: string, IsShowLoading?: boolean): Observable<ProjectUserEntity[]> {
    return this.intercept(this.http.get<ProjectUserEntity[]>(`${this.url}/${ProjectID}` + '/GetUserByProject', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }

  GetUserForAddProject(ProjectID: string, IsShowLoading?: boolean): Observable<AccountEntity[]> {
    return this.intercept(this.http.get<AccountEntity[]>(`${this.url}/${ProjectID}` + '/GetUserForAddProject', {
      observe: 'response',
      headers: this.GetHeaders()
    }), IsShowLoading).map(r => r.body);
  }
}
