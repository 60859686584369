import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CashReceiptEntity } from './CashReceipt.Entity';
import { HttpService } from "../../HttpService";
import { IEntity } from '../../IEntity.Entity';
@Injectable({
  providedIn: 'root'
})

export class CashReceiptService extends HttpService<CashReceiptEntity>{
  public url: string;

  constructor(private Http: HttpClient) {
    super(Http);
    this.ReportCode = "CashReceiptVoucher";
    this.url = "api/CashReceipts";
  }
  PrintVoucher(data: any) {
    return window.open(`${this.url}/${data.ID}` + "/PrintVoucher/" + data.VoucherCode);
  }

}
