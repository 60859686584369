import { SearchEntity } from "../../Search.Entity";

export class ProductSearchEntity extends SearchEntity {
  ID: string;
  ProductCode: string;
  ProductName: string;
  UnitPrice: number;
  Description: string;
  ProductCategoryID: string;
  ProductTypeID: string;
  PromotionID: string;
  public constructor() {
    super();

  }
}
