
import { SearchEntity } from "../../../Search.Entity";

export class SaleStageSearchEntity extends SearchEntity {
  ID: string;
  SaleStageCode: string;
  SaleStageName: string;
  Description: string;

  public constructor(SaleStage: any = null) {
    super();

  }
}
