
import { SearchEntity } from "../../../Search.Entity";

export class PurchaseStageSearchEntity extends SearchEntity {
  ID: string;
  PurchaseStageCode: string;
  PurchaseStageName: string;
  Description: string;

  public constructor(PurchaseStage: any = null) {
    super();

  }
}
