
import { IEntity } from "../../../IEntity.Entity";

export class SaleStageEntity extends IEntity {
  ID: string;
  SaleStageCode: string;
  SaleStageName: string;
  InActive: boolean;
  Description: string;
  CompanyID: string;
  IsVisible: boolean;
  SaleCount: number;
  public constructor(SaleStage: any = null) {
    super();
  }
}
